* {
  font-family: "Roboto";
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'),
       url('./assets/fonts/Roboto-Medium.ttf') format('truetype')
}

body {
  letter-spacing: 0.5px;
}

#root {

}

.wallet-btn {
  width: 240px !important;
  height: 64px !important;
  margin-left: 28px !important;
  font-size: 20px !important;
  border-radius: 28px !important;
  background: #4b3dd0 !important;
  font-weight: bold !important;
  line-height: 27px !important;
  border: none !important;
  color: #fff !important;
}

.balance-btn {
  width: 240px !important;
  height: 64px !important;
  margin-left: 28px !important;
  font-size: 20px !important;
  border-radius: 28px !important;
  background: #01e1d9 !important;
  font-weight: bold !important;
  line-height: 24px !important;
  border: none !important;
  color: #000 !important;
  padding: 2px 16px;
}

.claim-btn {
  width: 360px !important;
  height: 64px !important;
  margin-left: 28px !important;
  font-size: 20px !important;
  border-radius: 28px !important;
  background: #01e1d9 !important;
  font-weight: bold !important;
  line-height: 24px !important;
  border: none !important;
  color: #000 !important;
  padding: 2px 16px;
}

.balance-btn:hover {
  cursor: pointer;
}

.balance-btn .is_staked {
  font-size: 14px;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
}
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #73469B;
  border-top-color: #e2eefd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
@keyframes animate-preloader {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.nft_container {
  width: 100%;
}

.nft-collection {
  width: 100%;
  margin-top: 100px;
}

.nft-title {
  font-size: 50px;
}

/* :root {
  --darkBlue: #000000;
  --lightBlue: #00abff;
  --textBlue: #283957;
  --lightGrey: #eaebee;
  --grey: #97abbd;
  --textGreen: #5f9544;
  --textRed: #c93644;
  --bgDashboard: #e7eef8;
} */

:root {
  --darkBlue: #101922;
  --lightBlue: #00e5d9;
  --midBlue: #4b3dd0;
  --midPurple: #a03eab;
}

.on_hover:hover {
  cursor: pointer;
}

.white {
  color: white;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.light_blue {
  color: var(--lightBlue);
}

.dark_blue {
  color: var(--darkBlue);
}

.mid_blue {
  color: var(--midBlue);
}

.mid_purple {
  color: var(--midPurple);
}

.d_flex {
  display: flex;
}

.align_items_center {
  align-items: center;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_around {
  justify-content: space-around;
}

.justify_content_right {
  justify-content: right;
}

.justify_content_left {
  justify-content: left;
}

.font_bold {
  font-weight: bold;
}

.font_600 {
  font-weight: 600;
}

.font_40 {
  font-size: 40px;
  line-height: 45px;
}

.font_38 {
  font-size: 38px;
  line-height: 43px;
}

.font_30 {
  font-size: 30px;
  line-height: 35px;
}

.font_28 {
  font-size: 28px;
  line-height: 33px;
}

.font_25 {
  font-size: 25px;
  line-height: 30px;
}

.font_23 {
  font-size: 23px;
  line-height: 28px;
}

.font_20 {
  font-size: 20px;
  line-height: 25px;
}

.font_18 {
  font-size: 18px;
  line-height: 20px;
}

.font_15 {
  font-size: 15px;
  line-height: 20px;
}

.font_10 {
  font-size: 10px;
  line-height: 15px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_0 {
  margin-top: 0;
}

.ml_150 {
  margin-left: 150px;
}

.mt_150 {
  margin-top: 150px;
}

.pt_100 {
  padding-top: 100px;
}

.ml_50 {
  margin-left: 50px;
}

.ml_40 {
  margin-left: 40px;
}

.ml_30 {
  margin-left: 30px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_0 {
  margin-left: 0;
}

.ml_auto {
  margin-left: auto;
}

.mr_50 {
  margin-right: 50px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_30 {
  margin-right: 30px;
}

.mr_20 {
  margin-right: 20px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_0 {
  margin-right: 0;
}

.mr_auto {
  margin-right: auto;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_0 {
  margin-bottom: 0;
}

.pb_10 {
  padding-bottom: 10px;
}